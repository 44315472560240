import React, { useEffect } from "react";
import {
  FormField,
  EmptyState,
  TextButton,
  Heading,
  Card,
  Box,
  Page,
  Button,
  Cell,
  Layout,
  Input,
  WixDesignSystemProvider,
  InputArea,
  ColorInput,
  NumberInput,
  ToggleSwitch,
  Notification,
  Loader,
  CircularProgressBar,
  MarketingLayout,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import { ReactTagManager } from "react-gtm-ts";
import { useTranslation } from "react-i18next";
const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const defaultConfig = {
  zIndex: 99999, // z-index css property of popup, increase if popup hides behind other elements
  popupBorderRadius: 24, // border-radius of the popup(rounded corners)
  popupMaxWidth: 500, // Max width of the popup
  secureMode: true, // true|false - If true, right-click and element inspection will be disabled
  showCloseIcon: true, // Show close icon to hide popup
  popupBackgroundColor: "#ffffff", // The background color of popup
  popupBackdropColor: "rgba(0, 0, 0, 0.6)", // Backdrop or overlay color behind popup
  iconColor: "#E2535C", // Icon color of popup (Please note there are 3 icons already present in the file (handIcon, adIcon, faceIcon) you can use any of the icons, just change icon name in constructModal function)
  btnBackgroundColor: "#E2535C", // Button(I've disabled AdBlock) background color
  btnColor: "#ffffff", // Button(I've disabled AdBlock) font color
  titleColor: "#000000", // Title color of popup
  descColor: "#000000", // Description color of popup
  helpTextColor: "#000000", // Help Text(How do I disable my adblocker?/Okay, I understand!) color of popup
  screenTwoStepsColor: "#000000", // Screen two ad block disable steps color
  titleText: "AdBlock Detected!", // Popup title Text
  descText:
    "It looks like you have an adblocker enabled. In order to support our website and continue accessing our content, please disable your ad blocker for this site.", // Popup description text
  btnText: "I've disabled AdBlock", // Button(I've disabled AdBlock) text
  screenOneHelpText: "How do I disable my adblocker?", // Screen one toggle helper text
  screenTwoHelpText: "Okay, I understand!", // Screen toggle toggle helper text
  screenTwoTitle: "To disable adblocker on this site:", // Screen two title text
  screenTwoSteps: [
    // Screen two adblock disable steps text
    "Click on the adblocker extension icon located at the top right corner of your browser",
    'From the menu choose "Disable on this site" or "Pause on this site"',
    "Refresh the page if not automatically refreshed",
  ],
};

const BASE_URL = `https://certifiedcode.wixsite.com/protect/_functions`;

const CONTENT = [
  "All features unlocked",
  "Protect your site from ad blockers",
  "Retain revenue from ads",
];

const INTERVAL = 4000;

function App() {
  const { t } = useTranslation()
  ReactTagManager.init(tagManagerArgs);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [enabled, setEnabled] = React.useState(false);
  const [showPreviewHint, setShowPreviewHint] = React.useState(false);
  const [isOpening, setIsOpening] = React.useState(false);
  const [showSettingSaved, setShowSettingSaved] = React.useState(false);
  const [trialRemainingDate, setTrialRemainingDate] = React.useState(0);
  const [showSaveHint, setShowSaveHint] = React.useState(false);
  const screenTwoSteps = t('adblock.screenTwoSteps', { returnObjects: true }) || [];
 
   
  const [config, setConfig] = React.useState({
    zIndex: 99999, // z-index css property of popup, increase if popup hides behind other elements
    popupBorderRadius: 24, // border-radius of the popup(rounded corners)
    popupMaxWidth: 500, // Max width of the popup
    secureMode: true, // true|false - If true, right-click and element inspection will be disabled
    showCloseIcon: true, // Show close icon to hide popup
    popupBackgroundColor: "#ffffff", // The background color of popup
    popupBackdropColor: "rgba(0, 0, 0, 0.6)", // Backdrop or overlay color behind popup
    iconColor: "#E2535C", // Icon color of popup (Please note there are 3 icons already present in the file (handIcon, adIcon, faceIcon) you can use any of the icons, just change icon name in constructModal function)
    btnBackgroundColor: "#E2535C", // Button(I've disabled AdBlock) background color
    btnColor: "#ffffff", // Button(I've disabled AdBlock) font color
    titleColor: "#000000", // Title color of popup
    descColor: "#000000", // Description color of popup
    helpTextColor: "#000000", // Help Text(How do I disable my adblocker?/Okay, I understand!) color of popup
    screenTwoStepsColor: "#000000", // Screen two ad block disable steps color
    // titleText: "AdBlock Detected!", // Popup title Text
    // descText:
    //   "It looks like you have an adblocker enabled. In order to support our website and continue accessing our content, please disable your ad blocker for this site.", // Popup description text
    // btnText: "I've disabled AdBlock", // Button(I've disabled AdBlock) text
    // screenOneHelpText: "How do I disable my adblocker?", // Screen one toggle helper text
    // screenTwoHelpText: "Okay, I understand!", // Screen toggle toggle helper text
    // screenTwoTitle: "To disable adblocker on this site:", // Screen two title text
    // screenTwoSteps: [
    //   // Screen two adblock disable steps text
    //   "Click on the adblocker extension icon located at the top right corner of your browser",
    //   'From the menu choose "Disable on this site" or "Pause on this site"',
    //   "Refresh the page if not automatically refreshed",
    // ],
    titleText: t('adblock.title'), 
    descText: t('adblock.description'), 
    btnText: t('adblock.buttonText'),
    screenOneHelpText: t('adblock.screenOneHelpText'),
    screenTwoHelpText: t('adblock.screenTwoHelpText'),
    screenTwoTitle: t('adblock.screenTwoTitle'),
    screenTwoSteps: Array.isArray(screenTwoSteps) ? screenTwoSteps : [screenTwoSteps],
  
  } as any);
  const [instanceData, setInstanceData] = React.useState(null as any);

  const isFree = instanceData?.instance?.isFree !== false;

  const [index, setIndex] = React.useState<number>(0);
  const [isUpgradeBannerOpen, setIsUpgradeBannerOpen] =
    React.useState<boolean>(isFree);

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % CONTENT.length);
    }, INTERVAL);

    return () => clearInterval(sliderInterval);
  }, [CONTENT, INTERVAL]);

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
    getTrial();
  }, []);

  useEffect(() => {
    setShowSaveHint(true);
  }, [config]);

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=2be342ae-3ac1-40c2-9e45-1be143c4330e&redirectUrl=https://certifiedcode.wixsite.com/protect/_functions/@certifiedcode/base-backend/auth`;
  }

  if (token || !instanceData) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text={t('loading')} />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
        setIsUpgradeBannerOpen(data.instance.isFree !== false);
      })
      .catch(() => {});
  }

  function getTrial() {
    fetch(`${BASE_URL}/premium`, {
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setTrialRemainingDate(data?.trial);
      });
  }

  function setSettings(isEnabled?: boolean) {
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        isEnabled: typeof isEnabled !== "undefined" ? isEnabled : enabled,
        config,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setConfig(data.config);
        setEnabled(data.isEnabled);
        setIsOpening(false);
        setShowSettingSaved(true);
        setShowSaveHint(false);
      })
      .catch(() => {});
    // } else {
    //   setIsUpgradeModalOpen(true)
    // }
  }

  function setPreview() {
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "POST",
      headers: {
        Authorization: instance || "",
      },
      body: JSON.stringify({
        configPreview: config,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setConfig(data.config);
        setEnabled(data.isEnabled);
        setIsOpening(false);
        setShowPreviewHint(true);
        window.open(instanceData?.site?.url + `/?protect_preview=true`);
      })
      .catch(() => {});
    // } else {
    //   setIsUpgradeModalOpen(true)
    // }
  }

  function getSettings() {
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setEnabled(data.isEnabled);
        if (data.config) {
          setConfig(data.config);
        }
        setIsOpening(false);
      })
      .catch(() => {});
  }

  const upgradeUrl = `https://www.wix.com/apps/upgrade/${"2be342ae-3ac1-40c2-9e45-1be143c4330e"}?appInstanceId=${
    instanceData?.instance?.instanceId
  }`;

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification show={showPreviewHint}>
        <Notification.TextLabel>
          {t('notification-msg')}
        </Notification.TextLabel>
        <Notification.CloseButton />
      </Notification>
      <Notification show={showSettingSaved}>
        <Notification.TextLabel>
          Settings saved successfully!{" "}
          {enabled
            ? t('notification-s-msg')
            : ""}
        </Notification.TextLabel>
        <Notification.CloseButton />
      </Notification>
      <Notification theme="premium" show={isUpgradeBannerOpen} type={"sticky"}>
        <Notification.TextLabel>
        {t('upgradeMessage', { content: t(`content.${index}`) })}
        </Notification.TextLabel>
        <Notification.ActionButton
          onClick={() => {
            window.open(upgradeUrl, "_blank");
          }}
        >
          {t('upgrade-button')}
        </Notification.ActionButton>
        <Notification.CloseButton
          onClick={() => setIsUpgradeBannerOpen(false)}
        />
      </Notification>
      <Page
        height="100vh"
        className={`${isUpgradeBannerOpen ? "App-header-notification" : ""}`}
      >
        <Page.Header
          title={
            <Box gap={"SP2"} alignItems="middle">
              <Heading>{t('ad-blocker-title')}</Heading>
            </Box>
          }
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              <Button
                skin="light"
                prefixIcon={<Icons.Preview />}
                onClick={() => {
                  setPreview();
                }}
              >
                {t('ad-blocker-preview')}
              </Button>
              <Button
                // skin=
                prefixIcon={<Icons.Check />}
                onClick={() => {
                  setSettings();
                }}
              >
                {t('ad-blocker-publish')}
              </Button>
              <Button
                skin="premium"
                prefixIcon={<Icons.PremiumFilled />}
                as="a"
                target="_blank"
                href={upgradeUrl}
              >
                {isUpgraded ? t('manage-plan-button') : t('upgrade-protect')}
              </Button>
            </Box>
          }
        />
        <Page.Content>
          <Layout>
            <Cell span={12}>
              <Card>
                <Card.Header
                  title={
                    <Box gap={"SP2"}>
                      <Heading size="medium" as="h2">
                        {t('protect-heading')}
                      </Heading>
                    </Box>
                  }
                  suffix={
                    <ToggleSwitch
                      checked={enabled}
                      onChange={() => {
                        setSettings(!enabled);
                      }}
                    />
                  }
                />

                {enabled ? (
                  <Card.Content>
                    <EmptyState
                      theme="section"
                      title={t('protect-card-title')}
                      subtitle={t('protect-card-subtitle')}
                    >
                      <Box direction="vertical" gap="SP2">
                        <TextButton
                          prefixIcon={<Icons.ExternalLink />}
                          as={"a"}
                          href={
                            (instanceData as any)["site"]
                              ? (instanceData as any)["site"]["url"]
                              : ""
                          }
                          target={"_blank"}
                        >
                         {t('live-site-button')}
                        </TextButton>
                        <CircularProgressBar
                          label={
                            isUpgraded
                              ? t('premium-msg')
                              : t('premium-time-msg', {trialRemainingDate})
                          }
                          skin={isUpgraded ? "standard" : "premium"}
                          showProgressIndication
                          value={isUpgraded ? 100 : 1 - trialRemainingDate / 30}
                        />{" "}
                      </Box>
                    </EmptyState>{" "}
                  </Card.Content>
                ) : (
                  <></>
                )}
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <Card.Header
                  title={t('card-header')}
                  suffix={
                    <Button
                      size="small"
                      skin="light"
                      prefixIcon={<Icons.Revert />}
                      onClick={() => {
                        // only reset text
                        setConfig({
                          ...config,
                          titleText: defaultConfig.titleText,
                          descText: defaultConfig.descText,
                          btnText: defaultConfig.btnText,
                          screenOneHelpText: defaultConfig.screenOneHelpText,
                          screenTwoHelpText: defaultConfig.screenTwoHelpText,
                          screenTwoSteps: defaultConfig.screenTwoSteps,
                        });
                      }}
                    >
                      {t('reset-button-text')}
                    </Button>
                  }
                />
                <Card.Content>
                  <Layout>
                    <Cell span={12}>
                      <FormField label={t('label-title')}>
                        <Input
                          value={config.titleText}
                          placeholder={defaultConfig.titleText}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              titleText: e.currentTarget.value,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField label={t('label-description')}>
                        <Input
                          value={config.descText}
                          placeholder={defaultConfig.descText}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>)  => {
                            setConfig({
                              ...config,
                              descText: e.currentTarget.value,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField label={t('label-button-text')}>
                        <Input
                          value={config.btnText}
                          placeholder={defaultConfig.btnText}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              btnText: e.currentTarget.value,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField label={t('label-screen-one-help')}>
                        <Input
                          value={config.screenOneHelpText}
                          placeholder={defaultConfig.screenOneHelpText}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              screenOneHelpText: e.currentTarget.value,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField label={t('label-screen-two-help')}>
                        <Input
                          value={config.screenTwoHelpText}
                          placeholder={defaultConfig.screenTwoHelpText}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              screenTwoHelpText: e.currentTarget.value,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField label={t('label-screen-two-step')}>
                        <InputArea
                          autoGrow={true}
                          minHeight={"100px"}
                          value={config.screenTwoSteps.join("\n")}
                          placeholder={defaultConfig.screenTwoSteps.join("\n")}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              screenTwoSteps: e.currentTarget.value.split("\n"),
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={12}>
                      <FormField label={t('label-screen-two-step-title')}>
                        <Input
                          value={config.screenTwoTitle}
                          placeholder={defaultConfig.screenTwoTitle}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              screenTwoTitle: e.currentTarget.value,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                  </Layout>
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <Card.Header
                  title={t('card-header-design')}
                  suffix={
                    <Button
                      size="small"
                      skin="light"
                      prefixIcon={<Icons.Revert />}
                      onClick={() => {
                        // only reset design
                        setConfig({
                          ...config,
                          popupBackgroundColor:
                            defaultConfig.popupBackgroundColor,
                          popupBackdropColor: defaultConfig.popupBackdropColor,
                          iconColor: defaultConfig.iconColor,
                          btnBackgroundColor: defaultConfig.btnBackgroundColor,
                          btnColor: defaultConfig.btnColor,
                          titleColor: defaultConfig.titleColor,
                          descColor: defaultConfig.descColor,
                          helpTextColor: defaultConfig.helpTextColor,
                          screenTwoStepsColor:
                            defaultConfig.screenTwoStepsColor,
                        });
                      }}
                    >
                      {t('reset-button-design')}
                    </Button>
                  }
                />
                <Card.Content>
                  <Layout>
                    <Cell span={4}>
                      <FormField label={t('label-background')}>
                        <ColorInput
                          value={config.popupBackgroundColor}
                          placeholder={defaultConfig.popupBackgroundColor}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              popupBackgroundColor: e,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={4}>
                      <FormField label={t('label-backdrop')}>
                        <ColorInput
                          value={config.popupBackdropColor}
                          placeholder={defaultConfig.popupBackdropColor}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              popupBackdropColor: e,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={4}>
                      <FormField label={t('label-icon-color')}>
                        <ColorInput
                          value={config.iconColor}
                          placeholder={defaultConfig.iconColor}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              iconColor: e,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={4}>
                      <FormField label={t('label-background-button')}>
                        <ColorInput
                          value={config.btnBackgroundColor}
                          placeholder={defaultConfig.btnBackgroundColor}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              btnBackgroundColor: e,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={4}>
                      <FormField label={t('label-button-color')}>
                        <ColorInput
                          value={config.btnColor}
                          placeholder={defaultConfig.btnColor}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              btnColor: e,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={4}>
                      <FormField label={t('label-title-color')}>
                        <ColorInput
                          value={config.titleColor}
                          placeholder={defaultConfig.titleColor}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              titleColor: e,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={4}>
                      <FormField label={t('label-description-color')}>
                        <ColorInput
                          value={config.descColor}
                          placeholder={defaultConfig.descColor}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              descColor: e,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={4}>
                      <FormField label={t('label-help-text-color')}>
                        <ColorInput
                          value={config.helpTextColor}
                          placeholder={defaultConfig.helpTextColor}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              helpTextColor: e,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={4}>
                      <FormField label={t('label-step-two-color')}>
                        <ColorInput
                          value={config.screenTwoStepsColor}
                          placeholder={defaultConfig.screenTwoStepsColor}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              screenTwoStepsColor: e,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                  </Layout>
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <Card.Header
                  title={t('card-advanced-title')}
                  suffix={
                    <Button
                      size="small"
                      skin="light"
                      prefixIcon={<Icons.Revert />}
                      onClick={() => {
                        // only reset text
                        setConfig({
                          ...config,
                          popupBorderRadius: defaultConfig.popupBorderRadius,
                          popupMaxWidth: defaultConfig.popupMaxWidth,
                          zIndex: defaultConfig.zIndex,
                          secureMode: defaultConfig.secureMode,
                          showCloseIcon: defaultConfig.showCloseIcon,
                        });
                      }}
                    >
                      {t('card-advanced-reset-button')}
                    </Button>
                  }
                />
                <Card.Content>
                  <Layout>
                    <Cell span={4}>
                      <FormField label={t('label-popup-radius')}>
                        <NumberInput
                          value={config.popupBorderRadius}
                          placeholder={defaultConfig.popupBorderRadius.toString()}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              popupBorderRadius: e,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={4}>
                      <FormField label={t('label-popup-width')}>
                        <NumberInput
                          value={config.popupMaxWidth}
                          placeholder={defaultConfig.popupMaxWidth.toString()}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              popupMaxWidth: e,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={4}>
                      <FormField label={t('label-popup-z-index')}>
                        <NumberInput
                          value={config.zIndex}
                          placeholder={defaultConfig.zIndex.toString()}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              zIndex: e,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={4}>
                      <FormField
                        label={t('secure-title')}
                        infoContent={t('secure-info')}
                      >
                        <ToggleSwitch
                          checked={config.secureMode}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              secureMode: e.currentTarget.checked,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                    <Cell span={4}>
                      <FormField
                        label={t('close-icon')}
                        infoContent={t('close-info')}
                      >
                        <ToggleSwitch
                          checked={config.showCloseIcon}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setConfig({
                              ...config,
                              showCloseIcon: e.currentTarget.checked,
                            });
                          }}
                        />
                      </FormField>
                    </Cell>
                  </Layout>
                </Card.Content>
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  size="tiny"
                  actions={
                    <Button
                      size="small"
                      priority="secondary"
                      suffixIcon={<Icons.WixForms />}
                      onClick={() => {
                        window.Intercom("startSurvey", 44499573);
                      }}
                    >
                      Share Feedback
                    </Button>
                  }
                  title="Looking for something else?"
                  description="Share your feedback with us"
                ></MarketingLayout>
              </Card>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
